import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
import { reaction, when } from "mobx";
import React from "react";
import { isBrowser } from '../env';
import { useOnMount } from "./lifecycle.hooks";

const ResizeObserver = isBrowser ? (window.ResizeObserver || Polyfill) : null;

export const useResizeObserver = (
  handler: ResizeObserverCallback,
  ref: React.RefObject<HTMLElement | SVGElement>
) => {
  const observer = ResizeObserver ? new ResizeObserver(handler) : null;
  useOnMount(() => {
    if (!observer) return;
    const disposers = [] as AnyFunction[];
    disposers.push(when(
      () => Boolean(ref?.current),
      () => {
        observer.observe(ref.current!);
        disposers.push(reaction(
          () => !Boolean(ref?.current),
          () => {
            ref.current && observer.unobserve(ref.current);
          },
        ));
      },
    ));
    return () => disposers.forEach(d => d());
  })
}