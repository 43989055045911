import { Expo, TimelineLite, TweenLite } from 'gsap';
import { action, toJS } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useAppContext } from '../../controllers/app.controller';
import { isBrowser } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useCreateResizeQueryWithRef } from '../../hooks/useCreateResizeQueryWithRef.hook';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import { GADataLayerCustomEntry } from '../../types/app.types';
import joinClassName from '../../utils/className.utils';
import { debounce } from '../../utils/debounce.utils';
import { makeDisposerController } from '../../utils/disposer.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { highPerf } from '../../utils/performance.utils';
import { getRandomNumericString } from '../../utils/random.utils';
import { observeVisibility } from '../../utils/visibilityObserver.util';
import tick from '../../utils/waiters.utils';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import Line from '../Line/Line';
import './CTAButton.scss';

export type CTAButtonProps = {
  className?: string,
  to?: string
  title: string,
  target?: string,
  rel?: string,
  onClick?: () => void,
  drawLine?: 'left' | 'right' | false,
  dark?: boolean,
  outlined?: boolean,
  ribbons?: boolean,
  isLoading?: boolean
  disabled?: boolean,
  Label?: string,
  LoadingLabel?: string,
  gaEvent?: GADataLayerCustomEntry,
  doNotAnimate?: boolean,
  animationDelay?: number,
}

const CTAButton: React.FC<CTAButtonProps> = props => {
  const { UI } = useAppContext();
  const { ref, query } = useCreateResizeQueryWithRef<HTMLButtonElement & HTMLAnchorElement>();
  const p = useProps(props);
  const wrapperRef = useObservableRef();
  const backdrop = useObservableRef();
  const stripeRed = useObservableRef();
  const stripeGreen = useObservableRef();
  const stripeBlue = useObservableRef();
  const labelRef = useObservableRef();
  const s = useStore(() => ({
    id: `CTAButton-${getRandomNumericString()}`,
    handleClick: () => {
      p.onClick?.();
      if (p.gaEvent) window.dataLayer.push(toJS(p.gaEvent));
    },
    get commonAttr() {
      return {
        className: 'CTAButtonButton',
        title: p.title,
        onClick: s.handleClick,
        children: <>
          { !p.outlined && <span className="CTAButtonBackdrop" ref={backdrop}/> }
          { p.ribbons && <>
            <span className="CTAButtonColorStripe blue" ref={stripeBlue}></span>
            <span className="CTAButtonColorStripe green" ref={stripeGreen}></span>
            <span className="CTAButtonColorStripe red" ref={stripeRed}></span>
          </>}
          <span className="CTAButtonTextLabel" ref={labelRef}>
            {p.isLoading ? p.LoadingLabel ?? p.Label : p.Label}
            {p.children}
          </span>
        </>,
      }
    },
    get lineLength() {
      return p.drawLine === 'left' ? query.left : query.rightFromViewportRight;
    },
    get disabled() {
      return p.disabled || p.isLoading;
    },
    alive: true,
    get shouldAnimate() {
      return isBrowser && highPerf && !UI.isFirstLoad && props.ribbons && !props.doNotAnimate;
    },
    // handleWrapperClick: () => {
    //   if (!s.shouldAnimate) return;
    //   const elWidth = query.width;
    //   const duration = .5;
    //   const tl = new TimelineLite();
    //   tl.add(TweenLite.to(labelRef.current, { x: elWidth, ease: Expo.easeOut, duration: duration * 1.38 }), 0)
    //   tl.add(TweenLite.to(backdrop.current, { x: elWidth, ease: Expo.easeOut, duration }), 0)
    //   tl.add(TweenLite.to(stripeBlue.current, { x: elWidth, ease: Expo.easeOut, duration }), 0)
    //   tl.add(TweenLite.to(stripeGreen.current, { x: elWidth, ease: Expo.easeOut, duration }), 0.01)
    //   tl.add(TweenLite.to(stripeRed.current, { x: elWidth, ease: Expo.easeOut, duration }), 0.02)
    // }
  }))
  useOnMount(() => {
    const els = [stripeRed.current, stripeGreen.current, stripeBlue.current, backdrop.current, labelRef.current].filter(i => i) as HTMLSpanElement[];
    const d = makeDisposerController();
    const setupResizeHandler = () => {
      const handler = debounce(async () => {
        await tick(50);
        if (!stripeRed.current) return;
        const elWidth = query.width;
        TweenLite.to(stripeRed.current, { x: -elWidth + 4, duration: .2 });
        TweenLite.to(stripeGreen.current, { x: -elWidth + 8, duration: .2 });
        TweenLite.to(stripeBlue.current, { x: -elWidth + 12, duration: .2 });
      });
      window.addEventListener('resize', handler);
      d.add(() => window.removeEventListener('resize', handler))
    }
    if (s.shouldAnimate) {
      const elWidth = query.width;
      TweenLite.set(els, { x: elWidth });
      const atTime = (time: number) => time + (props.animationDelay ?? .5);
      const animateIn = () => {
        const elWidth = query.width;
        const duration = .38;
        const tl = new TimelineLite();
        tl.add(TweenLite.fromTo(stripeRed.current, { x: elWidth }, { x: -elWidth + 4, ease: Expo.easeOut, duration }), atTime(0))
        tl.add(TweenLite.fromTo(stripeGreen.current, { x: elWidth }, { x: -elWidth + 8, ease: Expo.easeOut, duration }), atTime(.1))
        tl.add(TweenLite.fromTo(stripeBlue.current, { x: elWidth }, { x: -elWidth + 12, ease: Expo.easeOut, duration }), atTime(.2))
        tl.add(TweenLite.fromTo(backdrop.current, { x: elWidth }, { x: 0, ease: Expo.easeOut, duration }), atTime(0))
        tl.add(TweenLite.fromTo(labelRef.current, { x: '100%' }, { x: 0, ease: Expo.easeOut, duration: duration * 1.38 }), atTime(.3))
        tl.call(setupResizeHandler, [], atTime(.3 + duration))
      }
      observeVisibility(wrapperRef, {
        id: s.id,
        once: true,
        onBecomeVisible: animateIn,
      })
    } else {
      if (stripeRed.current) {
        const elWidth = query.width;
        TweenLite.set(stripeRed.current, { x: -elWidth + 4 });
        TweenLite.set(stripeGreen.current, { x: -elWidth + 8 });
        TweenLite.set(stripeBlue.current, { x: -elWidth + 12 });
        setupResizeHandler();
      }
    }
    d.add(action(() => s.alive = false));
    return d.disposer;
  })
  return <Observer children={() => (
    <div 
      id={s.id}
      className={joinClassName(
        'CTAButton',
        p.className,
        p.dark && 'dark',
        p.outlined && 'outlined',
        p.target === '_blank' && 'external',
        p.ribbons && 'renderRibbon',
        p.isLoading && 'loading',
        (s.disabled && !p.isLoading) && 'disabled',
      )} 
      data-line-position={p.drawLine}
      ref={wrapperRef}
      // onMouseUpCapture={s.handleWrapperClick}
    >
      {!!(isBrowser && p.drawLine && s.lineLength) && <Line
        className="CTAButtonDecoLine"
        width={s.lineLength}
        animate
        animationDelay={p.ribbons ? '.75s' : '0'}
      />}
      { p.to && !s.disabled ? (
        p.target === '_blank' ? <a
          href={p.to}
          ref={ref}
          target={p.target}
          rel={p.rel ?? (p.target === '_blank' ? 'noreferrer' : '')}
          {...s.commonAttr}
        /> : <AnimatedLink
          to={p.to}
          ref={ref}
          {...s.commonAttr}
        />
      ) : <button
        type="button"
        ref={ref}
        disabled={s.disabled}
        {...s.commonAttr}
      />}
    </div>
  )} />
}

export default CTAButton;